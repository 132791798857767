import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Helmet } from 'react-helmet';
import {Content, Layout} from '../components/atoms';
import { GatsbyRichText } from '../components/contentful-rich-text';



export const WebsitePageTemplate = (props) => {
    const post = get(props, 'data.contentfulWebsitePage');
    const siteTitle = get(props, 'data.site.siteMetadata.title');
    const body = get(post, 'body.json');

    return (
        <Layout location={props.location}>
            <Helmet title={`${post.title} | ${siteTitle}`} />
            <Content title={post.title}>
                <GatsbyRichText body={body} />
            </Content>
        </Layout>
    );
};

export default WebsitePageTemplate;

export const pageQuery = graphql`
  query WebsitePageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulWebsitePage(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        body
        json
      }
    }
  }
`;
